import { IEnvironment } from './i-environment';

export const environment: IEnvironment = {
  production: true,
  baseURL: '/api/v1/',
  logLevel: "information",
  logType: "backend",
  salt: 'QTZ4blFoYno0VngySHVHbDRsWHdaNVUySThpemlMUkZuaFA1ZU5mSVJ2UQ',
  serverURL: '',
  maxProfileFieldsCount: 50,
  envName: 'staging',
  translationsUrlPrefix: 'https://i18n.staging.ownid.com/',
  translationsUrlSuffix: '/web-app.json',
  allowMocks: true,
};
